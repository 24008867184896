export default {
  getPriority(row) {
    if (row.isBlocked) return 3;
    if (row.isAdmin) return 2;
    return 1
  },
  getRegularRows(statistics) {
    if (!statistics) return []

    return statistics.map(
      row => ({
        ...row,
        added: row.created + row.unarchived + row.editAdded,
        deleted: row.archived + row.editDeleted,
        priority: this.getPriority(row)
      })
    ).sort((a, b) => a.priority - b.priority)
  },
  getRegularTotals(rows) {
    return {
      initial: rows.reduce((total, { initial }) => total + initial, 0),
      created: rows.reduce((total, { created }) => total + created, 0),
      added: rows.reduce((total, { added }) => total + added, 0),
      deleted: rows.reduce((total, { deleted }) => total + deleted, 0),
      editAdded: rows.reduce((total, { editAdded }) => total + editAdded, 0),
      editDeleted: rows.reduce((total, { editDeleted }) => total + editDeleted, 0),
      archived: rows.reduce((total, { archived }) => total + archived, 0),
      unarchived: rows.reduce((total, { unarchived }) => total + unarchived, 0),
    }
  },
  getContractRows(statistics) {
    if (!statistics) return []

    return statistics.map(
      row => ({
        ...row,
        addedED: row.createdED + row.unarchivedED + row.editAddedED,
        addedNED: row.createdNED + row.unarchivedNED + row.editAddedNED,
        deletedED: row.archivedED + row.editDeletedED,
        deletedNED: row.archivedNED + row.editDeletedNED,
        priority: this.getPriority(row)
      })
    ).sort((a, b) => a.priority - b.priority)
  },
  getContractTotals(rows) {
    return {
      initialED: rows.reduce((total, { initialED }) => total + initialED, 0),
      initialNED: rows.reduce((total, { initialNED }) => total + initialNED, 0),
      createdED: rows.reduce((total, { createdED }) => total + createdED, 0),
      createdNED: rows.reduce((total, { createdNED }) => total + createdNED, 0),
      addedED: rows.reduce((total, { addedED }) => total + addedED, 0),
      addedNED: rows.reduce((total, { addedNED }) => total + addedNED, 0),
      deletedED: rows.reduce((total, { deletedED }) => total + deletedED, 0),
      deletedNED: rows.reduce((total, { deletedNED }) => total + deletedNED, 0),
      editAddedED: rows.reduce((total, { editAddedED }) => total + editAddedED, 0),
      editAddedNED: rows.reduce((total, { editAddedNED }) => total + editAddedNED, 0),
      editDeletedED: rows.reduce((total, { editDeletedED }) => total + editDeletedED, 0),
      editDeletedNED: rows.reduce((total, { editDeletedNED }) => total + editDeletedNED, 0),
      archivedED: rows.reduce((total, { archivedED }) => total + archivedED, 0),
      archivedNED: rows.reduce((total, { archivedNED }) => total + archivedNED, 0),
      unarchivedED: rows.reduce((total, { unarchivedED }) => total + unarchivedED, 0),
      unarchivedNED: rows.reduce((total, { unarchivedNED }) => total + unarchivedNED, 0),
    }
  }
}